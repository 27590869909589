import { render, staticRenderFns } from "./headers.vue?vue&type=template&id=47dd168d&scoped=true"
import script from "./headers.vue?vue&type=script&lang=js"
export * from "./headers.vue?vue&type=script&lang=js"
import style0 from "./headers.vue?vue&type=style&index=0&id=47dd168d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47dd168d",
  null
  
)

export default component.exports