<template>
  <footer class="page-footer">
    <div class="footer disFlex">
      <div class="links disFlex">
        <div class="footlogo disFlexs" @click="$router.push('/')"><img src="../../public/logo.png" alt=""></div>
        <div class="foot-column">
          <div class="name">Tìm nhanh</div>
          <div class="link-list">
            <div class="item underline" @click="$router.push('/us')">Về chúng tôi</div>
            <div class="item underline" @click="$router.push('/privacy')">Chính sách bảo mật</div>
            <!-- <div class="item underline" @click="$router.push('/returns')">Warranty and Returns</div> -->
            <div class="item underline" @click="$router.push('/terms')">Điều kiện hoàn trả</div>
            <!-- <div class="item underline" @click="$router.push('/policy')">Shipping Policy</div> -->
            <!-- <div class="item underline" @click="$router.push('/register')">Supplier Signup</div> -->
            <!-- <div class="item underline" @click="goOldService">Old JH Market Trade Card - Service Center</div> -->
            <!-- <div class="item underline" @click="goRetail">JH Market Retail Website</div> -->
          </div>
        </div>
        <div class="foot-column addr">
          <div class="name underline">Liên hệ</div>
          <div class="link-list">
            <a href="#" target="_blank" class="item underline"><i class="iconfont icon-dizhi"></i><span>Công ty TNHH
                Công Nghệ Mạng Việt Nam Douji
              </span></a>
            <a href="#" target="_blank" class="item underline"><i class="iconfont icon-dizhi"></i><span>Số 2b Phố Cây
                Thao, Khu Đô Thị Green Park, X. Hải Xuân, Tp. Móng Cái, Quảng Ninh
              </span></a>
            <a href="#" target="_blank" class="item underline"><i
                class="iconfont icon-youxiang"></i><span>vietnamdouji@gmail.com</span></a>
            <a href="tel: 0785310666" class="item underline"><i
                class="iconfont icon-tel"></i><span>0785310666</span></a>
            <!-- <a href="mailto:sales@jh.market" class="item underline"><i
                class="iconfont icon-youxiang"></i><span>sales@jh.market</span></a> -->
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'footers',
  props: {

  },
  methods: {
    goRetail() {
      this.$message.warning('JH Market Retail Website is coming soon!')
    },
    goOldService() {
      this.$message.warning('Old JH Market Trade Card - Service Center is coming soon!')
    }
  }
}
</script>

<style scoped>
.footlogo {
  width: 200px;
  height: 200px;
}
</style>