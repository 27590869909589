<template>
  <div class="head">
    <div class="header pc">
      <div class="wrapper disFlex" v-if="hasLogin">
        <div class="item username disFlex" @click.stop="toggleUser" ref="dropUser">
          <p>Chào mừng,</p>
          <span>{{ userInfos.firstName + userInfos.lastName || "" }}</span>
          <i class="iconfont icon-jiantou-copy-copy" :class="{ active: toggleUserActive }"></i>
          <div class="userdown" :class="{ active: toggleUserActive }">
            <div class="op" @click.stop="changeIndex({ typename: 'Account' })">
              Tài khoản của tôi
            </div>
            <!-- <div class="op" @click.stop="changeIndex({ typename: 'WishList' })">
              My Wish List
            </div> -->
            <div class="op" @click.stop="changeIndex({ typename: 'Orders' })">
              Lệnh của tôi
            </div>
            <!-- <div class="op">Send Invitations</div> -->
            <div class="op" @click="logout">Thoát</div>
          </div>
        </div>
        <!-- <div class="item underline" @click="$router.push('/quickorder')">
          Đặt hàng nhanh
        </div> -->
      </div>
      <div class="wrapper disFlex" v-else>
        <div class="item">Chào mừng đến với LuckBuy!</div>
        <div class="item underline" @click="openLogin()">Đăng nhập</div>
        <span class="item">hoặc</span>
        <div class="item underline" @click="$router.push('/register')">
          Tạo tài khoản giao dịch mới
        </div>
      </div>
    </div>
    <!-- {{inputTextList}} -->
    <div class="top">
      <div class="wrapper disFlex">
        <i class="menubtn m iconfont icon-icmenu2" @click="menuChange"></i>
        <img src="../../public/logo.png" class="logo" alt="" @click="$router.push('/')" />
        <div class="pc">
          <div class="fr disFlexs">
            <div class="disFlex">
              <!-- <div class="proitem underline">Compare Products <span>(1 item)</span></div> -->
              <div class="search disFlex">
                <el-input v-model="searchValue" placeholder="Tìm kiếm toàn bộ cửa hàng tại đây"
                  class="searinp"></el-input>
                <div class="searbtn disFlexs iconfont icon-sousuo" @click="searchInp"></div>
              </div>
              <div class="cart disFlex" ref="dropCart">
                <div class="iconfont icon-gouwuche" @click="toggleCart"></div>
                <i class="cartnum" v-if="hasLogin" style="cursor: pointer" @click="toggleCart">{{ cartlist.length
                  }}</i><i class="cartnum" v-else>0</i>
                <div class="drop" :class="{ active: toggleCartActive }">
                  <div class="close iconfont icon-guanbi" @click="toggleCartActive = false"></div>
                  <div class="cartno">
                    <div class="cartnr" v-if="hasLogin && cartlist.length > 0">
                      <div class="items-total disFlex">
                        <div class="itemnum">
                          <b>{{ cartlist.length }}</b>Sản phẩm trong giỏ hàng
                        </div>
                        <div class="subtotal">
                          <div class="label">Tổng phụ giỏ hàng</div>
                          <div class="amount textr">đ{{ cartsubtotal }}</div>
                        </div>
                      </div>
                      <button class="primary" @click="ProceedClick">
                        Tiến hành thanh toán
                      </button>
                      <div class="minicart" v-show="inputTextList.length > 0">
                        <div class="minicart-items">
                          <div class="item" v-for="(item, i) in cartlist" :key="i" style="
                              padding-bottom: 20px;
                              border-bottom: 1px solid #f3f3f3;
                              margin-bottom: 10px;
                            ">
                            <div class="product disFlex">
                              <div class="product-image">
                                <img :src="item.productPic" alt="" />
                              </div>
                              <div class="product-item-details">
                                <div class="product-item-name underline" @click="productNameClick(item)">
                                  {{ item.productName }}
                                </div>
                                <div class="price">đ{{ item.price }}</div>
                                <div class="details-qty disFlex" v-if="oldList.length > 0">
                                  <div class="qtyl disFlex">
                                    <label for="">Số lượng:</label>
                                    <el-input type="text" v-model="inputTextList[i].inputText" />
                                    <button class="btn" v-if="
                                        oldList[i].inputText !=
                                        inputTextList[i].inputText
                                      " @click="handleUpteCartNum(item, i)">
                                      Cập nhật
                                    </button>
                                  </div>
                                  <div class="qtyr">
                                    <i class="edit iconfont icon-bianji" @click="
                                        $router.push(
                                          `/detail?id=${item.productId}&from=${item.id}&qty=${item.quantity}`
                                        )
                                      "></i>
                                    <i class="del iconfont icon-shanchu" @click="onCartDelete(item.id)"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- <div class="notice iconfont">
                              This product is available for Pre Order. Please refer to the lead time on the product page.
                            </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="secondary textc underline" @click="viewAndEditClick">
                        Xem và chỉnh sửa giỏ hàng
                      </div>
                    </div>
                    <div class="cartnr textc" v-else>
                      <b>Không có sản phẩm nào trong giỏ hàng của bạn.</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="tel">0785310666</div> -->
            <a href="tel:0785310666" class="tel underline disFlex"><img src="@/assets/icon/header_phone_icon.png"
                alt="" />0785310666</a>
          </div>
        </div>
        <div class="m">
          <div class="fr disFlex">
            <div class="searbtn iconfont icon-sousuo" @click="toggleSearch()"></div>
            <div class="tel iconfont icon-tel"></div>
            <div class="cart iconfont icon-gouwuche"></div>
            <i class="cartnum" v-if="hasLogin" style="cursor: pointer" @click="toggleCart">{{ cartlist.length }}</i><i
              class="cartnum" v-else>0</i>
          </div>
        </div>
      </div>
      <div class="wrapper m" v-if="toggleSearchActive">
        <div class="search">
          <input type="text" placeholder="Tìm kiếm toàn bộ cửa hàng tại đây" class="searinp" v-model="searchValue"
            @click="searchInp()" />
        </div>
      </div>
    </div>

    <div class="nav pc" v-if="
        $route.path != '/checkout/payment' &&
        $route.path != '/checkout/shipping'
      ">
      <div class="wrapper">
        <div class="navTab disFlex">
          <ul class="disFlex">
            <li class="first" v-for="(v, i) in menuList" :key="i">
              <div class="op disFlex" @click="
                  $router.push({ path: '/list', query: { categoryId: v.id } })
                ">
                <span>{{ v.name }}</span><i class="iconfont icon-jiantou-copy-copy" v-if="v.childList.length > 0"></i>
              </div>
              <ul v-if="v.childList.length > 0">
                <li class="second" v-for="(v1, i1) in v.childList" :key="i1">
                  <div class="ops disFlex" @click="
                      $router.push({
                        path: '/list',
                        query: { categoryId: v1.id },
                      })
                    ">
                    <span>{{ v1.name }}</span><i class="iconfont icon-jiantou" v-if="v1.childList.length > 0"></i>
                  </div>
                  <ul v-if="v1.childList.length > 0">
                    <li class="third" v-for="(v2, i2) in v1.childList" :key="i2">
                      <div class="ops disFlex" @click="
                          $router.push({
                            path: '/list',
                            query: { categoryId: v2.id },
                          })
                        ">
                        <span>{{ v2.name }}</span><i class="iconfont icon-jiantou" v-if="v2.childList.length > 0"></i>
                      </div>
                      <ul v-if="v2.childList.length > 0">
                        <li class="fourth" v-for="(v3, i3) in v2.childList" :key="i3">
                          <div class="ops disFlex" @click="
                              $router.push({
                                path: '/list',
                                query: { categoryId: v3.id },
                              })
                            ">
                            <span>{{ v3.name }}</span>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <!-- <li class="first">
              <div class="op disFlex" @click="$router.push({ path: '/blog' })">
                <span>Blog</span>
              </div>
            </li>
            <li class="first">
              <div class="op disFlex" @click="$router.push({ path: '/brands' })">
                <span>Brands</span>
              </div>
            </li>
            <li class="first">
              <div class="op disFlex" @click="$router.push({ path: '/tracking' })">
                <span>Track Shipment</span>
              </div>
            </li>
            <li class="first">
              <div class="op disFlex" @click="$router.push({ path: '/us' })">
                <span>Why Choose Us</span>
              </div>
            </li>
            <li class="first">
              <div class="op disFlex" @click="$router.push({ path: '/rewards' })">
                <span>JH Rewards</span>
              </div>
            </li> -->
          </ul>
          <!-- <a class="site disFlex" href="###"><span>go to</span><i>RETAIL Site</i></a> -->
        </div>
      </div>
    </div>

    <div class="nav m" :class="{ have: navShow }" @click="navShow = false">
      <div class="navnr" @click.stop>
        <div class="navtype disFlex">
          <div class="item" :class="{ active: menuActive == 0 }" @click="menuActive = 0">
            Thực đơn
          </div>
          <div class="item" :class="{ active: menuActive == 1 }" @click="menuActive = 1">
            Tài khoản
          </div>
        </div>
        <div class="navtab" v-if="menuActive == 0">
          <ul>
            <li class="first" v-for="(v, i) in menuList" :key="i">
              <div class="op disFlex" @click="
                  v.childList.length == 0 &&
                    ($router.push({
                      path: '/list',
                      query: { categoryId: v.id },
                    }),
                    (navShow = false))
                ">
                <span>{{ v.name }}</span><i class="iconfont icon-jiantou-copy-copy" v-if="v.childList.length > 0"></i>
              </div>
              <ul v-if="v.childList.length > 0">
                <li class="second" v-for="(v1, i1) in v.childList" :key="i1">
                  <div class="ops disFlex" @click="
                      v1.childList.length == 0 &&
                        ($router.push({
                          path: '/list',
                          query: { categoryId: v1.id },
                        }),
                        (navShow = false))
                    ">
                    <span>{{ v1.name }}</span><i class="iconfont icon-jiantou" v-if="v1.childList.length > 0"></i>
                  </div>
                  <ul v-if="v1.childList.length > 0">
                    <li class="third" v-for="(v2, i2) in v1.childList" :key="i2">
                      <div class="ops disFlex" @click="
                          v2.childList.length == 0 &&
                            ($router.push({
                              path: '/list',
                              query: { categoryId: v2.id },
                            }),
                            (navShow = false))
                        ">
                        <span>{{ v2.name }}</span><i class="iconfont icon-jiantou" v-if="v2.childList.length > 0"></i>
                      </div>
                      <ul v-if="v2.childList.length > 0">
                        <li class="fourth" v-for="(v3, i3) in v2.childList" :key="i3">
                          <div class="ops disFlex" @click="
                              v3.childList.length == 0 &&
                                ($router.push({
                                  path: '/list',
                                  query: { categoryId: v3.id },
                                }),
                                (navShow = false))
                            ">
                            <span>{{ v3.name }}</span>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <!-- <li class="first">
              <div class="op disFlex" @click="$router.push({ path: '/blog' })">
                <span>Blog</span>
              </div>
            </li>
            <li class="first">
              <div class="op disFlex" @click="$router.push({ path: '/brands' })">
                <span>Brands</span>
              </div>
            </li>
            <li class="first">
              <div class="op disFlex" @click="$router.push({ path: '/tracking' })">
                <span>Track Shipment</span>
              </div>
            </li>
            <li class="first">
              <div class="op disFlex" @click="$router.push({ path: '/us' })">
                <span>Why Choose Us</span>
              </div>
            </li>
            <li class="first">
              <div class="op disFlex" @click="$router.push({ path: '/rewards' })">
                <span>JH Rewards</span>
              </div>
            </li> -->
          </ul>
          <!-- <a class="site disFlex" href="###"><span>go to</span><i>RETAIL Site</i></a> -->
        </div>
        <div class="navtab account" v-if="menuActive == 1">
          <div class="disFlexs" v-if="hasLogin">
            <div class="item username disFlexs" @click.stop="toggleUser" ref="dropUser">
              <div class="disFlex">
                <p>Chào mừng,</p>
                <span>{{
                  userInfos.firstName + userInfos.lastName || ""
                  }}</span>
                <i class="iconfont icon-jiantou-copy-copy" :class="{ active: toggleUserActive }"></i>
              </div>
              <div class="userdown" :class="{ active: toggleUserActive }">
                <div class="op" @click.stop="
                    changeIndex({ typename: 'Account' });
                    navShow = false;
                  ">
                  Tài khoản của tôi
                </div>
                <!-- <div class="op" @click.stop="
                    changeIndex({ typename: 'WishList' });
                    navShow = false;
                  ">
                  My Wish List
                </div> -->
                <div class="op" @click.stop="
                    changeIndex({ typename: 'Orders' });
                    navShow = false;
                  ">
                  Lệnh của tôi
                </div>
                <!-- <div class="op">Send Invitations</div> -->
                <div class="op" @click="
                    logout();
                    navShow = false;
                  ">
                  Thoát
                </div>
              </div>
            </div>
            <!-- <div class="item underline" @click="
                $router.push('/quickorder');
                navShow = false;
              ">
              Đặt hàng nhanh
            </div> -->
          </div>
          <div class="disFlexs" v-else>
            <div class="item">Chào mừng đến với LuckBuy!</div>
            <div class="item underline" @click="openLogin()">Đăng nhập</div>
            <span class="item">hoặc</span>
            <div class="item underline" @click="$router.push('/register')">
              Tạo tài khoản giao dịch mới
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="Đăng nhập" :visible.sync="dialogLoginVisible" @close="closeLogin()" width="60%" class="signfix">
      <el-form :model="itemForm" :rules="rules" ref="itemForm">
        <el-form-item label="Địa chỉ email" prop="username" class="item must disFlexs">
          <el-input v-model="itemForm.username" placeholder="Tên tài khoản"></el-input>
        </el-form-item>
        <el-form-item label="Mật khẩu" prop="password" class="item must disFlexs">
          <el-input type="password" v-model="itemForm.password" placeholder="Tên tài khoản"></el-input>
        </el-form-item>
      </el-form>
      <div class="signbtm disFlex">
        <div class="forgot underline undercolor">Quên mật khẩu?</div>
        <div class="fr disFlex">
          <div class="create underline undercolor" @click="
              $router.push('/register');
              navShow = false;
            ">
            Tạo tài khoản giao dịch mới
          </div>
          <el-button class="btn" @click="onSubmit('itemForm')">Đăng nhập</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { Loading } from "element-ui";
import {
  productCateList,
  cartList,
  cartDelete,
  search,
  userInfo,
  login,
  updateQuantity,
} from "@/service/api.ts";
import store from "@/store";
import numberPrecision from "number-precision";
export default {
  name: "my-headers",
  data() {
    return {
      currentInput: null,
      toggleUserActive: false,
      toggleCartActive: false,
      toggleSearchActive: false,
      qty: "",
      dialogLoginVisible: false,
      searchValue: "",

      menuList: [],
      itemForm: {
        username: "",
        password: "",
      },
      inputList: [],
      rules: {
        username: [
          { required: true, message: "please enter", trigger: "blur" },
        ],
        password: [
          { required: true, message: "please enter", trigger: "blur" },
          {
            min: 8,
            max: 100,
            message: "Chiều dài lớn hơn 8 và nhỏ hơn 100",
            trigger: "blur",
          },
        ],
      },
      navShow: false,
      menuActive: 0,
    };
  },
  computed: {
    ...mapState([
      "hasLogin",
      "userInfos",
      "dialogLogin",
      "keyword",
      "cartlist",
      "inputTextList",
      "oldList",
    ]),

    cartsubtotal() {
      const { cartlist } = this;
      if (cartlist == null || cartlist.length == 0 || cartlist == undefined) {
        return 0;
      }
      let mount = 0;
      cartlist.forEach((item) => {
        mount = mount + numberPrecision.times(item.price, item.quantity);
      });

      return mount;
    },
  },
  watch: {
    // 监听计算属性，它代理了store中的状态
    dialogLogin(newValue, oldValue) {
      // 当状态发生变化时，这个函数会被调用
      this.dialogLoginVisible = newValue;
    },
    keyword(newValue, oldValue) {
      // 当状态发生变化时，这个函数会被调用
      this.searchValue = newValue;
    },
  },
  beforeMount() {
    this.getCartList();
  },
  mounted() {
    document.addEventListener("click", this.clearToggleActive);
  },
  created() {
    if (!!localStorage.getItem("hasLogin")) {
      this.setLogin(JSON.parse(localStorage.getItem("userInfos")));
    }
    this.getCateList();
  },
  methods: {
    ...mapMutations([
      "setLogin",
      "deleteLogin",
      "setDialogLogin",
      "setKeyword",
    ]),
    clearToggleActive(e) {
      let dropUser = this.$refs.dropUser;
      let dropCart = this.$refs.dropCart;
      if (dropUser && !dropUser.contains(e.target) && this.toggleUserActive) {
        this.toggleUserActive = false;
      }
      if (dropCart && !dropCart.contains(e.target) && this.toggleCartActive) {
        this.toggleCartActive = false;
      }
    },
    getCateList() {
      productCateList().then((res) => {
        this.menuList = res.data;
      });
    },
    getCartList() {
      let that = this;
      if (!that.hasLogin) return;
      store.dispatch("getCarList");
    },
    onCartDelete(ids) {
      let that = this;
      if (!that.hasLogin) return;
      let loadingInstance = Loading.service({ fullscreen: true });
      cartDelete({ ids: ids }).then((res) => {
        // that.$message.success(res.message);
        loadingInstance.close();
        that.getCartList();
      });
    },
    changeIndex(typename) {
      this.$emit("changeIndex", typename.typename);
      this.$router.push({ path: "/center", query: typename });
    },
    toggleUser() {
      this.toggleUserActive = !this.toggleUserActive;
    },
    toggleCart(bool) {
      if (bool === false) {
        this.toggleCartActive = false;
        return;
      }
      this.toggleCartActive = !this.toggleCartActive;
    },
    toggleSearch() {
      this.toggleSearchActive = !this.toggleSearchActive;
    },
    searchInp() {
      this.$router.push({
        path: "list",
        query: { searchValue: this.searchValue },
      });
    },
    openLogin() {
      this.setDialogLogin(true);
    },
    closeLogin() {
      this.setDialogLogin(false);
    },
    onSubmit(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          login({
            username: this.itemForm.username,
            password: this.itemForm.password,
          }).then((res) => {
            this.$message.success(res.message);
            let token = res.data.tokenHead + res.data.token;
            localStorage.setItem("token", token);
            this.closeLogin();
            userInfo().then((res) => {
              this.setLogin(res.data);
              location.reload();
            });
          });
        } else {
          console.log("Gửi lỗi!!");
          return false;
        }
      });
    },
    logout() {
      localStorage.removeItem("token");
      this.deleteLogin();

      let currentRoute = JSON.parse(localStorage.getItem("currentRoute"));
      if (currentRoute.meta.requiresAuth) {
        this.$router.push("/");
      } else {
        location.reload();
      }
    },

    viewAndEditClick() {
      this.$router.push("/checkout/cart");
      this.toggleCartActive = false;
    },

    ProceedClick() {
      this.$router.push("/checkout/shipping");
      this.toggleCartActive = false;
    },

    productNameClick(item) {
      this.$router.push({
        path: "/detail",
        query: { id: item.productId },
      });
      this.toggleCartActive = false;
    },
    handleUpteCartNum(pro, index) {
      updateQuantity({
        id: pro.id,
        quantity: store.state.inputTextList[index].inputText,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success("Thành công");
          this.getCartList();
        }
      });
    },
    menuChange() {
      this.navShow = !this.navShow;
      console.log(this.navShow);
    },
  },
};
</script>

<style scoped>
.first {
  cursor: pointer;
}

.el-input-number {
  width: 60px !important;
}
</style>
